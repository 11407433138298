var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.showCard)?_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_c('h2',{staticClass:"text-h3"},[_vm._v("Instituto Alfa Laurel")]),_c('v-spacer'),_c('span',[_c('img',{attrs:{"src":require("../assets/logo.png"),"alt":"insituto alfa laurel logo","width":"100","height":"100"}})])],1),_c('v-card-subtitle',[_c('p',{staticClass:"text-body-1"},[_vm._v(" Ingrese sus datos para comenzar el examen. ")])]),_c('v-card-text',[_c('validation-observer',{ref:"exam",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"Nombre del alumno","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"filled":"","outlined":"","label":"Nombre del alumno"},model:{value:(_vm.examRequest.student_name),callback:function ($$v) {_vm.$set(_vm.examRequest, "student_name", $$v)},expression:"examRequest.student_name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"Código del alumno","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Código del alumno","maxlength":"10","filled":"","outlined":""},model:{value:(_vm.examRequest.student_code),callback:function ($$v) {_vm.$set(_vm.examRequest, "student_code", $$v)},expression:"examRequest.student_code"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"Profesor","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.users,"error-messages":errors,"clearable":"","outlined":"","filled":"","label":"Profesor","placeholder":"Seleccione a un profesor","no-data-text":"La lista de profesores no esta disponible"},on:{"change":_vm.resetValues},model:{value:(_vm.examRequest.user_id),callback:function ($$v) {_vm.$set(_vm.examRequest, "user_id", $$v)},expression:"examRequest.user_id"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"Materia","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"clearable":"","error-messages":errors,"filled":"","outlined":"","items":_vm.subjects,"disabled":!_vm.examRequest.user_id,"placeholder":"Selecciona una materia","label":"Materia"},on:{"input":_vm.getExams},model:{value:(_vm.examRequest.subject_id),callback:function ($$v) {_vm.$set(_vm.examRequest, "subject_id", $$v)},expression:"examRequest.subject_id"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"Examen","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"clearable":"","error-messages":errors,"filled":"","outlined":"","loading":_vm.exams.loading,"items":_vm.exams.values,"no-data-text":"No existen examenes para el profesor y materia seleccionada","laber":"Examen","placeholder":"Selecciona un examen"},model:{value:(_vm.examRequest.exam_id),callback:function ($$v) {_vm.$set(_vm.examRequest, "exam_id", $$v)},expression:"examRequest.exam_id"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Código del examen"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Código del examen","placeholder":"Ingrese el código del examen","maxlength":"10","trim":"","filled":"","outlined":"","error-messages":errors,"type":"password"},model:{value:(_vm.teacher_code),callback:function ($$v) {_vm.teacher_code=$$v},expression:"teacher_code"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"block":"","color":"primary","x-large":"","loading":_vm.loading},on:{"click":_vm.onSubmit}},[_vm._v(" Aceptar ")])],1)],1)],1)],1)]}}],null,false,3741474327)})],1)],1):_c('v-sheet',[_c('v-form',{ref:"alum-exam"},[_c('v-container',[_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"grey lighten-2"},[_c('section',{staticClass:"grid-container"},[_c('p',{staticClass:"grid-item-1 text-subtitle-2 font-weight-medium text-capitalize"},[_vm._v(" Materia: "+_vm._s(_vm.subjectSelected[0].text)+" ")]),_c('p',{staticClass:"grid-item-2 text-subtitle-2 font-weight-medium text-capitalize"},[_vm._v(" Examen: "+_vm._s(_vm.examenSelected[0].text)+" ")]),_c('p',{staticClass:"grid-item-3 text-subtitle-2 font-weight-medium text-capitalize"},[_vm._v(" nombre del alumno: "+_vm._s(_vm.examRequest.student_name)+" ")]),_c('p',{staticClass:"grid-item-4 text-subtitle-1 font-weight-medium text-capitalize"},[_vm._v(" Tiempo: "+_vm._s(_vm.examRequest.minutes_assigns)+" ")]),_c('p',{staticClass:"grid-item-5 text-subtitle-1 font-weight-medium text-capitalize",class:_vm.timerStyle},[_vm._v(" Tiempo restante: "+_vm._s(_vm.timer)+" ")])])]),_c('v-card-text',[_c('v-form',[_c('v-container',[_c('v-row',_vm._l((_vm.questions),function(question,index){return _c('v-col',{key:question.id,class:[
                                index % 2 === 0 ? 'grey lighten-4' : '' ],attrs:{"cols":"12"}},[_c('p',{staticClass:"text-uppercase text-subtitle-1 font-weight-bold"},[_vm._v(" "+_vm._s(index + 1)+".- "+_vm._s(question.question)+" ")]),_c('v-radio-group',{attrs:{"row":""},model:{value:(question.answer),callback:function ($$v) {_vm.$set(question, "answer", $$v)},expression:"question.answer"}},_vm._l((question.options),function(option){return _c('v-radio',{key:option.option,staticClass:"mr-auto",attrs:{"value":option.option,"label":option.option}})}),1)],1)}),1)],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"x-large":"","color":"primary","block":""},on:{"click":function($event){return _vm.$store.commit('settings/TOGGLE_DIALOG', true)}}},[_vm._v(" Terminar ")])],1)],1)],1)],1)],1)],1)],1)],1)],1),_c('dialog-component',{attrs:{"message":_vm.dialogMessage,"loading":_vm.loading},on:{"store":_vm.store}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }